.header_main_container {
  height: 55px !important;
  overflow: hidden !important;
  background: #f9f8f8;
}
.Mewahtrans_GPS_heading {
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 15px !important;
  color: #000000 !important;
}
.header_time {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 12px !important;
  color: #000000 !important;
  margin-left: 10px !important;
  margin-top: 3px !important;
}
.header_links {
  margin-right: 30px !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 15px !important;
  color: #070707 !important;
  text-transform: capitalize !important;
  transition: 0.2s ease-in-out !important;
  cursor: pointer !important;
}
.fuel-price-container {
  height: 70% !important;
  width: 80% !important;
  background: #046307 !important;
  padding: 5px 2px !important;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fuel-price {
  font-weight: 400 !important;
  font-size: 17px !important;
  line-height: 10px !important;
  color: #ffffff !important;
  text-align: center; /* Center the text inside the typography */
}
.fuel-value {
  display: block; /* use block so the margin top works */
  margin-top: 6px !important;
}
.header_links_smallScreen {
  margin-left: 30px !important;
}
.active {
  color: #00a3ff !important;
}
.header_links:hover {
  color: #00a3ff !important;
  transition: 0.2s ease-in-out !important;
}
.header_links_icons {
  margin-right: 10px !important;
  font-size: 20px !important;
}
.onSmallScreen {
  display: none !important;
}
.onLargeScreen {
  display: flex !important;
}
.menu_icon {
  margin-right: 20px !important;
}
@media screen and (max-width: 600px) {
  .onSmallScreen {
    display: flex !important;
  }
  .onLargeScreen {
    display: none !important;
  }
}
