.MTL004_heading {
  font-weight: 700 !important;
  font-size: 25px !important;
  line-height: 15px !important;
  color: #030303 !important;
  margin-bottom: 0px !important;
}
.sidebar_search {
  background: #f7f7f7 !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 8px !important;
  width:100%;
  height: 25px !important;
  margin: 4px 0px !important;
  padding: 10px 10px 10px 10px !important;
}
.sidebar_search_driverdetail {
  background: #f7f7f7 !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 8px !important;
  /* width:30%; */
  min-height: 25px !important;
  margin: 4px 0px !important;
  padding: 10px 10px 10px 10px !important;
}
.table_cells_values,
.table_cells_label {
  font-weight: 400 !important;
  font-size: 15px !important;
  /* line-height: 15px !important; */
  color: #00a3ff !important;
  text-transform: capitalize !important;
}
.table_cells_values {
  color: #030303 !important;
}
.tabs_active,
.tabs {
  background: #c4c4c4 !important;
  border-radius: 20px !important;
  height: 35px !important;
  /* padding: 5px 20px !important; */
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 13px !important;
  color: #000000 !important;
  width:100% !important;
  /* margin-top: 15px !important;
  margin-bottom: 15px !important; */
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  cursor: pointer !important;
  transition: 0.2s ease-in-out !important;
  vertical-align: middle !important;
  text-align: center !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.tabs:hover {
  background-color: #197ec1 !important;
  color: white !important;
  transition: 0.2s ease-in-out !important;
}
.tabs_active {
  background-color: #197ec1 !important;
  color: white !important;
}
.tabs_container {
  display: flex !important;
  justify-content: space-between !important;
  padding: 12px 0 !important;
}
.route_history_map {
  margin-top: 10px !important;
}
@media screen and (max-width: 599px) {
  .tabs_container {
    display: block !important;
    justify-content: center !important;
  }
  .tabs {
    margin: 10px 0px !important;
  }
}
.cells_table {
  margin-top: 1vw !important;
  margin-bottom: 1vw !important;
}
.table_cell {
  margin-top: 0.5vw !important;
  vertical-align: middle !important;
}
.rest-container {
  height: calc(100vh - 270px) !important;
  max-height: calc(100vh - 270px) !important;
}
.rest-container-inner {
  height: calc(100vh - 275px) !important;
  max-height: calc(100vh - 275px) !important;
  position: relative;
}
.rest-container-date {
  height: calc(100vh - 312px) !important;
  max-height: calc(100vh - 312px) !important;
}
.rest-container-after-date {
  height: calc(100vh - 363px) !important;
  max-height: calc(100vh - 363px) !important;
}
.meter-view {
  background-color: #f7f7f7;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex; /* Enable Flexbox */
  justify-content: space-between; /* Space out child elements */
  align-items: center; /* Vertically center child elements */
  /* width: 15%; Set width as needed */
}
.gauge-chart svg {
  width: 100% !important;
  /* height: 150px !important; */
  margin-top: 50px !important; 
}